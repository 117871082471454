import React from "react";
import { Header, Footer } from "../../shared";
import { MainContext } from "../../../App";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Certificate = () => {
  const [forgotModal, setForgotModal] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);
  const [showSupportModal, setShowSupportModal] = React.useState(false);
  const [canvaUri, setCanvaUri] = React.useState(null);
  const context = React.useContext(MainContext);
  const is_2fa = localStorage.getItem("is_2fa") === "true" ? true : false;

  const navigate = useNavigate();
  const currentURL = window.location.pathname;

  useEffect(() => {
    
  }, [currentURL, context.login.isLoggedIn]);

  const loadCanvaUri = async () => {

  };

  useEffect(() => {
    
  }, []);

  return (
    <>
      <div className="">
        <Header
          className="relative"
          login={context.login}
          loginModal={context.loginModal}
          setLoginModal={context.setLoginModal}
          forgotModal={forgotModal}
          setForgotModal={setForgotModal}
          signUp={signUp}
          setSignUp={setSignUp}
          verifyOTPModal={context.verifyOTPModal}
          setVerifyOTPModal={context.setVerifyOTPModal}
          LoginLimitedModal={context.isLoginLimited}
          setLoginLimitedModal={context.setLoginLimitedModal}
          showTermsModal={showTermsModal}
          setShowTermsModal={setShowTermsModal}
          showSupportModal={showSupportModal}
          setShowSupportModal={setShowSupportModal}
        />
      </div>
      <div className="flex flex-col article-section px-8">
        <div className="py-12 text-gray-g1 flex flex-col gap-1"></div>
        <div className="text-2xl font-bold">
          <a href="/certificate">Certificate</a>
        </div>
        <div className="text-gray-g1 flex flex-col gap-1 w-full md:w-[80%] m-auto">
          <h1 className="text-center text-4xl font-bold mb-4 text-green-g2">
            Coming soon...
          </h1>
        </div>
      </div>
      <Footer
        setShowTermsModal={setShowTermsModal}
        setShowSupportModal={setShowSupportModal}
      />
    </>
  );
};

export default Certificate;
